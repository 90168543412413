import * as React from "react";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import logo from "../../components/atoms/Logo";

export const HomePage = () => {
  const [redirect, setRedirect] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleSubmit = (event) => {
    setErrorMessage(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = {
      field1: data.get("field1"),
      field2: data.get("field2"),
      field3: data.get("field3"),
    };
    if (
      result.field1 === "1908" &&
      result.field2 === "1995" &&
      result.field3 === "535"
    ) {
      setRedirect(true);
      localStorage.setItem("cookie", 1);
    } else {
      setErrorMessage(true);
    }
  };

  React.useEffect(() => {
    if (redirect) {
      window.location.href = "/result-mannenweekend-2023";
    }
  }, [redirect]);

  return (
    <Grid
      container
      component="main"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        background: `#000 center no-repeat url(${logo})`,
      }}
    >
      <CssBaseline />
      <Grid
        container
        item
        xs={12}
        sm={8}
        md={6}
        component={Paper}
        elevation={6}
        square
        justifyContent="center"
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "orange", color: "black" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h1" color="white" pb={2}>
            Mannenweekend 2023
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography component="h2" variant="h2" color="white">
                      HINT #1
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="field1"
                      label=""
                      name="field1"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="h2" variant="h2" color="white">
                      HINT #2
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="field2"
                      label=""
                      name="field2"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="h2" variant="h2" color="white">
                      HINT #3
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="field3"
                      label=""
                      name="field3"
                    />
                  </Grid>
                  {errorMessage && (
                    <Grid item xs={12}>
                      <Alert severity="error">De code is onjuist!</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{ mt: 3, mb: 2 }}
                      disableTouchRipple
                    >
                      Geef mij het antwoord!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
