import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { HomePage } from "./components/pages/HomePage";
import { ResultPage } from "./components/pages/ResultPage";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const muiTheme = createTheme({
  typography: {
    h1: {
      fontFamily: '"Patrick Hand SC"',
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: '"Patrick Hand SC"',
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    body1: {
      fontFamily: '"Patrick Hand SC"',
      fontSize: "1rem",
    },
  },
});

const theme = {
  ...muiTheme,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.8)",
          border: "4px solid rgba(255,255,255,.2)",
          borderRadius: "5px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "rgba(255,255,255,.5)",
          borderColor: "rgba(255,255,255,.5)",
          borderStyle: "solid",
          borderWidth: "2px",
          color: "#000",
          fontWeight: "bold",
          fontSize: "2.5rem",
          height: "1",
          padding: "0",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "0 !important",
          textAlign: "center",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: "orange",
          color: "#000",
          fontWeight: "bold",
          height: "48px",
          "&$disabled": {
            opacity: 0.8,
          },
          "&:hover": {
            background: "white",
          },
        },
      },
    },
  },
};

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/result-mannenweekend-2023" element={<ResultPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
