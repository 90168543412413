import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import Box from "@mui/material/Box";

const Countdown = () => {
  return <div className="needle"></div>;
};

export const ResultPage = () => {
  const countDownBefore = 5;
  const lengthOfCountdown = 10;
  const [isDestination, setIsDestination] = useState(false);
  const [isReveal, setIsReveal] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const waitForIt = async () => {
    await new Promise((resolve) => setTimeout(resolve, countDownBefore * 1000));

    doCountdown();
  };

  const doCountdown = async () => {
    let i = 0;
    do {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setCountdown(lengthOfCountdown - i);
      i++;
    } while (i <= lengthOfCountdown);

    setIsDestination(true);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setIsReveal(true);
  };

  useEffect(() => {
    if (countdown === 0) {
      waitForIt();
    }
  }, []);

  if (localStorage.getItem("cookie") !== "1") {
    return <Navigate to="/" />;
  }

  return (
    <>
      {countdown > 0 ? (
        <Box
          sx={{
            background: "radial-gradient(#fff, #757575)",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "4px",
              height: "4000px",
              background: "#000",
              top: "0",
              left: "50%",
            }}
          />
          <div
            style={{
              position: "absolute",
              height: "4px",
              width: "4000px",
              background: "#000",
              left: "0",
              top: "50%",
            }}
          />
          <div
            style={{
              borderRadius: "500px",
              position: "absolute",
              height: "500px",
              width: "500px",
              border: "4px solid #fff",
              left: "calc(50% - 250px)",
              top: "calc(50% - 250px)",
            }}
          />
          <div
            style={{
              borderRadius: "550px",
              position: "absolute",
              height: "550px",
              width: "550px",
              border: "4px solid #fff",
              left: "calc(50% - 275px)",
              top: "calc(50% - 275px)",
            }}
          />
          <Countdown />
          <span
            style={{
              color: "#000",
              fontSize: "20rem",
              textAlign: "center",
              display: "block",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            {countdown}
          </span>
        </Box>
      ) : (
        <Box
          sx={{
            background: "#000",
            height: "100vh",
            overflow: "hidden",
          }}
          display="grid"
          gridTemplateColumns="repeat(25, 1fr)"
        >
          {Array.from({ length: 13 }).map((_, idx) => {
            const isCenterBox = idx === 6;
            return (
              <Box
                key={idx}
                gridColumn={`span ${isCenterBox ? 15 : 5}`}
                style={
                  isCenterBox
                    ? {
                        background: `black`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }
                    : {
                        position: "relative",
                        paddingBottom: "100%",
                      }
                }
              >
                {isCenterBox ? (
                  <>
                    {isDestination ? (
                      <span
                        style={{
                          color: "#fff",
                          display: "block",
                          textAlign: "center",
                          fontSize: "10rem",
                          lineHeight: 1,
                          opacity: isReveal ? 1 : 0,
                          transition: "opacity 1s ease-in-out 2s",
                        }}
                      >
                        Oostende, Belgie
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#fff",
                          display: "block",
                          fontSize: "10rem",
                          lineHeight: 1,
                        }}
                      >
                        We gaan naar...
                      </span>
                    )}
                  </>
                ) : (
                  <img
                    src={`/images/oostende${idx + 1}.png`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      backgroundSize: "auto",
                      opacity: isReveal ? 1 : 0,
                      transform: isReveal ? "scale(1)" : "scale(0)",
                      transition: `all 1s ease-in-out ${Math.random()}s`,
                    }}
                    alt=""
                  />
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};
